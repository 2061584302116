import React, { memo, useCallback, useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
const containerStyle = {
  width: "100%",
  height: "100vh",
};

function App() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });

  const [map, setMap] = useState(null);
  const [centerLoc, setCenterLoc] = useState();
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [mapData, setMapData] = useState(null);

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(centerLoc);
      map.fitBounds(bounds);

      setMap(map);
    },
    [centerLoc]
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fetchData = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setCenterLoc({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_BASEURL}driver/list`
      );
      const jsonData = await response.json();
      setMapData(jsonData?.data?.driverList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Initial data fetch
    const interval = setInterval(fetchData, 35000); // Regular data fetch every 35 seconds
    return () => {
      clearInterval(interval);
    };
  }, []);

  return isLoaded && mapData && centerLoc ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={centerLoc}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <>
        {mapData?.map((val, index) => (
          <Marker
            key={index}
            icon={{ url: "r3.png" }}
            position={{
              lat: val?.latitude,
              lng: val?.longitude,
            }}
            onClick={() => setSelectedMarker(val)}
          ></Marker>
        ))}
        <Marker icon={{ url: "pin.png" }} position={centerLoc}></Marker>
        {selectedMarker && (
          <InfoWindow
            position={{
              lat: selectedMarker?.latitude,
              lng: selectedMarker?.longitude,
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div style={{ mt: 0 }}>
              <p>{selectedMarker?.name}</p>
              {/* <p>{selectedMarker?.vehiclesNumber}</p> */}
              <p>
                <a href={`tel:${selectedMarker?.phone}`}>
                  {selectedMarker?.phone}
                </a>
              </p>

              <p></p>
            </div>
          </InfoWindow>
        )}
      </>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(App);
